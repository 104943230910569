import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import { NavLink } from "react-router-dom";

const ServicesHome_6 = ({ bgImg, serviceName, serviceText }) => {
  const { rpdata } = useContext(GlobalDataContext);

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <article
      className="w-[380px] h-[400px] mx-10 my-5 relative bg-cover bg-center"
      style={{ backgroundImage: `url("${bgImg}")` }}
    >
        <NavLink
          to={
            rpdata?.autoGntLandingFromService
              ? `/${serviceName.replaceAll(/[\^*@!"#$%&/()=?¡!¿'\\ ]/g, "-").toLowerCase()}`
              : "/services"
          }
          className=""
          onClick={goToTop}
        >
      <div className="w-full h-full absolute bg-[#00000052] p-5 z-[9] text-white flex items-end justify-center">
        <h5 className="text-[20px] leading-[24px] text-white ">{serviceName}</h5>
      </div>
      </NavLink>
    </article>
  );
};

export default ServicesHome_6;
