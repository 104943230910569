import React, { useState, useContext } from "react";
import { GlobalDataContext } from "../../../context/context";
import IconsRedes from "../IconRedes";
import Navmenu from "../MenuList";
import { MdCalendarToday, MdLocationOn } from "react-icons/md";
import { IoMdClock } from "react-icons/io";
import { ButtonContent } from "../../global/boton/ButtonContent";
import { GiHamburgerMenu } from "react-icons/gi";


const Header_8 = () => {
  const { rpdata } = useContext(GlobalDataContext);
  const [isOpen, setOpen] = useState(false);
  return (
    <header className="w-full p-5 flex flex-col items-center justify-center absolute z-[99]">
      <section className="w-full lg:w-4/5 p-2 flex justify-around bg1">
        <div className="w-[300px]">
          <img
            src={rpdata?.dbPrincipal?.logo}
            alt="company:logo"
            className="w-full"
          />
        </div>
        <div className="lg:flex gap-5 items-center hidden text-white">
          {rpdata?.dbPrincipal?.location?.slice(0, 1).map((item, index) => {
            return (
              <div key={index} className="flex items-center">
                <MdLocationOn className="text-[25px] rounded-md" />
                <span className="pl-3">{item.address}</span>
              </div>
            );
          })}
          {rpdata?.dbPrincipal?.workdays.slice(0, 1).map((item, index) => {
            return (
              <li
                key={index}
                className="py-2 items-center justify-center hidden lg:flex"
              >
                <div className="flex">
                  <div className="flex items-center pr-5">
                    <MdCalendarToday size={20} />
                    <span className="pl-3">{item.day}</span>
                  </div>
                  <div className="flex items-center pr-5">
                    <IoMdClock size={20} />
                    <span className="pl-3">
                      {rpdata?.dbPrincipal?.workHours[index].hour}
                    </span>
                  </div>
                </div>
              </li>
            );
          })}

          <div>
          <ButtonContent/>
          </div>
        </div>
      </section>
      <section className="w-full lg:w-4/5 bg-[#383a3a4b] py-2 flex justify-between items-center">
      <nav className="flex px-5">
          <div className="relative hidden md:block">
            <Navmenu classes={'text-white p-2'} />
          </div>
          <div
            className='md:hidden flex justify-center py-1 rounded-md w-[50px] h-[40px] bg-white'
            onClick={() => setOpen(true)}
          >
            <GiHamburgerMenu className="w-full h-full text-black" />
          </div>
        </nav>
        <div className="px-5">
            <IconsRedes classes={'text-white'}/>
        </div>
      </section>


      {isOpen ? (
        <div className="fixed z-50 top-0 right-0 left-0 bg-white w-full h-screen overflow-y-auto pb-10">
          <div className="flex justify-end w-[90%] mx-auto relative">
            <button
              className="font-bold text-[20px] mt-5"
              onClick={() => setOpen(false)}
            >
              X
            </button>
          </div>
          <div className="w-[60%] mx-auto pb-10">
            <img
              src={rpdata?.dbPrincipal?.logo}
              alt="logo"
              loading="lazy"
              className="w-full"
            />
          </div>
          <div className="w-4/5 mx-auto ">
            <Navmenu classes="flex-col gap-3" />
          </div>

            <div className="flex flex-col items-center mt-28">
              <span className="font-bold">Follow Us On:</span>
            <IconsRedes classes="flex justify-center gap-5 text-black"/>
            </div>
        </div>
      ) : null}
    </header>
  );
};

export default Header_8;
